import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import Tabs from "./tabs";
import Logo from "../assets/Roll.png";
import { useTheme } from "@mui/material/styles";
import WalletIcon from "@mui/icons-material/Wallet";
import { IconButton } from "@mui/material";
import { ConnectWallet } from "@thirdweb-dev/react";
function Navbar() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledAppbar position="static" elevation={0}>
      <StyledContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LogoImage src={Logo} alt="Logo" />
          <Typography
            variant="h3"
            sx={{
              lineHeight: "normal",
              letterSpacing: "0.1563rem",
              fontWeight: "300",
              fontSize: "1.5rem",
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
            }}
          >
            RollingDoge
          </Typography>
        </Box>
        <Tabs />
        <ConnectWallet
          className="web3Button-color"
          dropdownPosition={{
            side: "bottom",
            align: "center",
          }}
          detailsBtn={() => {
            return (
              <IconButton sx={{ color: "#159bd7" }}>
                <WalletIcon fontSize="large" />
              </IconButton>
            );
          }}
        />
      </StyledContainer>
    </StyledAppbar>
  );
}

const StyledAppbar = styled(AppBar)(() => ({
  height: "5rem",
  backgroundColor: "#000",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "1rem",
  fontWeight: 600,
  gap: "0.5rem",
  color: "white",
  textTransform: "none",
  marginLeft: "auto",
}));

const StyledContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  height: "100%",
});

const LogoImage = styled("img")({
  width: "2.75rem",
  height: "2.5rem",
  marginRight: 16,
  "@media (max-width: 600px)": {
    width: "2.75rem",
    height: "2.5rem",
  },
});

export default Navbar;
