import "./styles/Home.css";
import "./App.css";
import Footer from "./Components/footer";
import Main from "./Components/main";
import NavBar from "./Components/navbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom"; // Import Router
import { useContract, useContractRead } from "@thirdweb-dev/react";
import { useAddress } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import RollingDoge from "./abis/constant.json"
import RollingDogeROI from "./abis/constant2.json"
const theme = createTheme();

export default function Home() {
  const [dataa, setData] = useState();
  const [smartContractBalance, setSmartContractBalance] = useState("");
  const [totalUsers, setTotalUsers] = useState("");

  const sdk = new ThirdwebSDK("Avalanche", {
    clientId: "e7c5681c66aaca6a7ec6b5d9ef13b46d",
  });

  const { contract } = useContract(
    "0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD", RollingDogeROI
  );

  const { totalUserss, isLoadingg } = useContractRead(
    contract,
    "totalUsers",
    []
  );

  const address = useAddress();

  const getData = async () => {
    if (contract !== undefined && address !== undefined) {
      const __data = await contract.call("Users", [address]);
      const __totalUsers = await contract.call("totalUsers", []);
      setTotalUsers(__totalUsers.toString());

      setData(__data);
    }
  };

  useEffect(() => {
    getData();
  }, [contract, address]);

  useEffect(() => {
    console.log(totalUserss);
  }, [isLoadingg]);

  const getSmartContractBalance = async () => {
    const contract = await sdk.getContract(
      "0x1eED3F4C32e8dB397471D3049F00d7c7d663A135", RollingDoge
    );
    let balance = await contract.erc20.balanceOf(
      "0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD"
    );

    setSmartContractBalance(balance.displayValue);
  };

  useEffect(() => {
    getSmartContractBalance();
  }, []);

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <NavBar />
          <Main
            dataa={dataa}
            smartContractBalance={smartContractBalance}
            totalusers={totalUsers}
          />
          <Footer />
        </ThemeProvider>
      </Router>
    </div>
  );
}
