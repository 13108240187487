import { Box, Typography } from "@mui/material";
import React from "react";

function ReferralCards() {
    const levelList = [
      { value: 6, label: "Level 1" },
      { value: 4, label: "Level 2" },
      { value: 3, label: "Level 3" },
      { value: 2, label: "Level 4" },
      { value: 1, label: "Level 5" },
      { value: 0.5, label: "Level 6" },
    ];
    
    const levelList2 = [
      { value: 0.5, label: "Level 7" },
      { value: 0.5, label: "Level 8" },
      { value: 0.5, label: "Level 9" },
      { value: 0.5, label: "Level 10" },
      { value: 0.5, label: "Level 11" },
      { value: 0.5, label: "Level 12" },
    ];
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "@media (max-width: 600px)": {
                flexWrap: "wrap",
            }
          }}
        >
          {levelList.map((level) => (
            <Box className="levelBox">
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                {level.label}
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {level.value}%
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "@media (max-width: 600px)": {
                flexWrap: "wrap",
            }
          }}
        >
          {levelList2.map((level) => (
            <Box className="levelBox">
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                {level.label}
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {level.value}%
              </Typography>
            </Box>
          ))}
        </Box>
      </>
    );
}

export default ReferralCards;