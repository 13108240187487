import {
  Box,
  Button,
  TextField,
  Typography,
  styled,
  InputBase,
} from "@mui/material";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import RollingDoge from "../abis/constant.json"
import RollingDogeROI from "../abis/constant2.json"
import CircleIcon from "@mui/icons-material/Circle";
import { Web3Button } from "@thirdweb-dev/react";
import { useAddress } from "@thirdweb-dev/react";
import { useContract, useContractRead } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import Withdraw from "./withdraw";
import HowItWorks from "./Details/HowItWorks";
import Landing from "./LandingPage/landing";
import Logo from "../assets/Layer 1 1.svg";
import ICON_1 from "../assets/icon_1.png";
import ICON_2 from "../assets/icon_2.png";
import ICON_3 from "../assets/icon_3.png";
import ICON_4 from "../assets/icon_4.png";
import ICON_5 from "../assets/icon_5.png";
import ICON_6 from "../assets/icon_6.png";
import ReferralCards from "./Referals/referralCard";
function Main({ dataa, smartContractBalance, totalusers }) {
  // console.log(dataa);

  const inputRef = useRef(null);
  const [reffCode, setReffCode] = useState(""); // Initialize with 0 by default
  const location = useLocation();
  const tokenAddress = "0x1eED3F4C32e8dB397471D3049F00d7c7d663A135"; //0x1eED3F4C32e8dB397471D3049F00d7c7d663A135
  const beeboxAddress = "0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD"; //0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD
  const address = useAddress();
  const { contract } = useContract(
    "0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD", RollingDogeROI
  );
  const { data, isLoading } = useContractRead(contract, "owner", []);

  const [totalProfit, setTotalProfit] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [smartContractBalancee, setsmartContractBalance] = useState(0);
  const [referralIncome, setReferralIncome] = useState(0);

  const [investedAmount, setInvestedAmount] = useState(0);
  const [refferedBy, setRefferedBy] = useState("");

  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refParam = searchParams.get("ref");

    if (refParam !== null) {
      setReffCode(refParam); // Convert the string to an integer
    } else {
      // getData();
      setReffCode(data);
    }
  }, [location.search, isLoading]);

  useEffect(() => {
    if (
      dataa !== undefined &&
      dataa.referredBy !== "0x0000000000000000000000000000000000000000"
    ) {
      let value = ethers.utils.formatEther(dataa.referralEarning.toString());
      value = parseFloat(value).toFixed(4);
      setReferralIncome(value);

      value = ethers.utils.formatEther(dataa.totalProfit.toString());
      value = parseFloat(value).toFixed(4);
      setTotalProfit(value);

      value = ethers.utils.formatEther(dataa.investedAmount.toString());
      value = parseFloat(value).toFixed(4);
      setInvestedAmount(value);

      setRefferedBy(dataa.referredBy);
    }
  }, [dataa]);

  function coptReferral() {
    const currentDomain = window.location.origin;
    const referralLink = `${currentDomain}/?ref=${address}`;
    const textarea = document.createElement("textarea");
    textarea.value = referralLink;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    alert(`Referral link "${referralLink}" copied to clipboard!`);
  }

  useEffect(() => {
    if (smartContractBalance !== undefined) {
      setsmartContractBalance(smartContractBalance);
    }
    if (totalusers !== undefined) {
      setTotalUsers(totalusers);
    }
  }, [smartContractBalance, totalusers]);

  useEffect(() => { }, []);

  return (
    <>
      <Landing />

      <Box
        sx={{
          // minHeight: "9.5625rem",
          // height: "100vh",
          padding: "10%",
          backgroundColor: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          "@media (max-width: 600px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            border: "2px solid #159bd7",
            justifyContent: "center",
            width: "90%",
            padding: "5% 10%",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "@media (max-width: 600px)": {
                flexWrap: "wrap",
              },
            }}
          >
            <Box className="valueBox">
              <img src={ICON_1}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Total Users
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {totalUsers}
              </Typography>
            </Box>
            <Box className="valueBox">
              <img src={ICON_2}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Contract Balance
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {smartContractBalancee} $ROLL
              </Typography>
            </Box>

            <Box className="valueBox">
              <img src={ICON_3}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Total Profit
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {totalProfit} $ROLL
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "@media (max-width: 600px)": {
                flexWrap: "wrap",
              },
            }}
          >
            <Box className="valueBox">
              <img src={ICON_4}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Referral Earning
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {referralIncome} $ROLL
              </Typography>
            </Box>

            <Box className="valueBox">
              <img src={ICON_5}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Invested Amount
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {investedAmount} $ROLL
              </Typography>
            </Box>
            <Box className="valueBox">
              <img src={ICON_6}></img>
              <Typography
                sx={{
                  color: "#FFF",
                }}
              >
                Referred By
              </Typography>
              <Typography
                sx={{
                  color: "#adaab8",
                }}
              >
                {(refferedBy !== "" && refferedBy !== null && refferedBy !== undefined) ? `${refferedBy.slice(0, 4)}...${refferedBy.slice(-4)}` : "--"}

              </Typography>
            </Box>
          </Box>
        </Box>
        {/*
        <Box
          sx={{
            "@media (max-width: 600px)": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "3.5rem",
              minWidth: "19.27481rem",
              border: "3px solid #F9D131",
              color: "white",
              backgroundColor: "#29261D",
              letterSpacing: "0.53125rem",
              "&:hover": {
                backgroundColor: "white",
                color: "#29261D",
              },
              padding: "0.5rem",
              fontSize: "1.2rem",
              "@media (max-width: 600px)": {
                marginTop: "1.5rem",
              },
            }}
            onClick={coptReferral}
          >
            Copy My Referral Link
          </Button>
        </Box>
        */}
      </Box>
      <Box
        sx={{
          // minHeight: "80vh",
          backgroundColor: "#000",
          padding: "5rem 10rem",
          display: "flex",
          justifyContent: "space-between",
          "@media (max-width: 600px)": {
            padding: "2rem",
            flexWrap: "wrap",
          },
        }}
      >
        <Box
          sx={{
            minHeight: "20rem",
            width: "55%",
            backgroundColor: "#FFF",
            borderRadius: "20px",
            border: "1px solid #159bd7",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            flexWrap: "wrap",
            "@media (max-width: 600px)": {
              padding: "1rem",
              gap: "1rem",
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              color: "#159bd7",
              fontSize: "2.5rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            DEPOSIT
          </Typography>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "35ch" },
              "@media (max-width: 600px)": {
                "& > :not(style)": { m: 1, width: "25ch" },
              },
            }}
            noValidate
            autoComplete="off"
            color={"white"}
          >
            <InputBase
              label="Enter Amount"
              variant="outlined"
              type="number"
              fullWidth
              placeholder="Enter Amount"
              color="warning"
              inputRef={inputRef}
              sx={{
                input: { color: "#000" },
                border: "2px solid #159bd7",
                borderRadius: "10px",
                padding: "10px",
                paddingLeft: "20px",
                backgroundColor: "#FFF",
              }}
            />
          </Box>
          {!isApproved && (
            <Web3Button
              className="web3Button-color"
              contractAddress={tokenAddress}
              contractAbi={RollingDoge}
              switchNetwork={5}
              action={async (contract) => {
                try {
                  const approvedAmount = await contract.call("allowance", [
                    address,
                    beeboxAddress,
                  ]);
                  const investAmount = ethers.utils.parseEther(
                    inputRef.current.value
                  );

                  if (approvedAmount >= investAmount) {
                    setIsApproved(true);
                  } else {
                    await contract.call("approve", [
                      beeboxAddress,
                      investAmount,
                    ]);
                    setIsApproved(true);
                  }
                } catch (error) {
                  console.error(error); // HANDLE ERROR HERE SARANG
                }
              }}
            >
              Approve
            </Web3Button>
          )}

          {isApproved && (
            <Web3Button
              className="web3Button-color"
              contractAddress="0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD"
              contractAbi={RollingDogeROI}
              action={async (contract) => {
                console.log(reffCode);
                await contract.call("Invest", [
                  inputRef.current.value,
                  reffCode,
                ]);
              }}
            >
              Invest
            </Web3Button>
          )}
        </Box>
        <Withdraw UserData={dataa} />
      </Box>
      <Box
        sx={{
          // minHeight: "9.5625rem",
          padding: "5rem 10%",
          backgroundColor: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          "@media (max-width: 600px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            border: "2px solid #159bd7",
            justifyContent: "center",
            width: "90%",
            padding: "5% 10%",
            borderRadius: "20px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "2.5rem",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              textAlign: "center",
            }}
          >
            REFERRALS
          </Typography>
          <Box
            sx={{
              padding: "20px",
              "@media (max-width: 600px)": {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: "20px",
                minWidth: "100%",
                border: "1px solid #159bd7",
                color: "white",
                backgroundColor: "#159bd7",
                display: "flex",
                gap: { xs: "0.5rem", md: "0" },
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "#159bd7",
                  color: "#FFF",
                },
                padding: "0.5rem 30px",
                fontSize: "1.2rem",
              }}
              onClick={coptReferral}
            >
              <Typography >{(address !== "" && address !== null && address !== undefined) ? `${address.slice(0, 8)}...${address.slice(-8)}` : "--"}</Typography>
              <Typography textTransform="none">Copy My Referral Link</Typography>
            </Button>
          </Box>

          <ReferralCards />
        </Box>
      </Box>
    </>
  );
}


export default Main;