import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import Logo from "../../assets/Roll.png";
import SVVG from "../../assets/icon _ellipses_.svg";
import { useTheme } from "@mui/material/styles";
import WalletIcon from "@mui/icons-material/Wallet";
import useMediaQuery from "@mui/material/useMediaQuery";

function Landing() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        minHeight: "90vh",
        backgroundColor: "#100F0D",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "1rem",
        "@media (max-width: 600px)": {
          flexDirection: "column",
          justifyContent: "space-around",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <LandingLogo src={Logo} />
        {/*<Box
          sx={{
            padding: "10px 30px",
            backgroundColor: "#1541d5",
            borderRadius: "20px",
            fontSize: "0.9rem",
          }}
        >
           <Typography
            variant="p"
            sx={{
              color: "#FFF",
            }}
          >
            Best Investments in Avalanche Now.
          </Typography> 
        </Box>*/}
        <Typography
          variant="h2"
          sx={{
            color: "#FFF",
            fontSize: "3.625rem",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.145rem",
            "@media (max-width: 600px)": {
              fontSize: "1rem",
            },
          }}
        >
          Invest Better with Rolling
        </Typography>

        <Typography
          variant="h6"
          sx={{
            color: "#adaab8",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.145rem",
            "@media (max-width: 600px)": {
              fontSize: "0.8rem",
            },
          }}
        >
          Do Not Miss our 19% Referral Bonus.
        </Typography>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
            },
          }}
        >
          {isSmallScreen && (
            <Button
              className="web3Button-color"
              variant="contained"
              sx={{
                borderRadius: "3.5rem",
                minWidth: "13.04069rem",
                backgroundColor: "#F9D131",
                color: "#2E2006",
                marginTop: "2rem",
                letterSpacing: "0.1875rem",
                "&:hover": {
                  backgroundColor: "white",
                },
                padding: "0.5rem",
                fontSize: "1rem",
              }}
            >
              Investments
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
const LogoImage2 = styled("img")({
  width: "10.9355rem",
  height: "10.32769rem",
});
const Image = styled("img")({
  width: "1.8235rem",
  height: "0.45588rem",
  marginRight: 16,
});

const LandingLogo = styled("img")({
  height: "65vh",
  "@media (max-width: 600px)": {
    height: "40vh"
  }
});

const LogoImage = styled("img")({
  width: "29.0625rem",
  height: "27.625rem",
  "@media (max-width: 600px)": {
    width: "15.0625rem",
    height: "13.625rem",
  },
});
export default Landing;
