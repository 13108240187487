import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

export default function Card({ image, title, description }) {
  return (
    <Box
      sx={{
        maxWidth: "23.1875rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2.5rem",
        // borderRight: "1px solid white",
        paddingX: "2.5rem",
        "@media (max-width: 600px)": {
          borderRight: "unset",
          paddingY: "2rem",
          flexDirection: "column",
          gap: 0,
        },
      }}
    >
      <StyledStack>
        <Image src={image} alt="" />
        <Typography
          sx={{
            color: "#F9D131",
            fontSize: "1.85rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            letterSpacing: "0.145rem",
            textTransform: "uppercase",
            paddingTop: "1rem",
            minWidth: "22rem",
            textAlign: "left",
            "@media (max-width: 600px)": {
              fontSize: "1.3rem",
              fontWeight: "400",
              minWidth: "15rem",
              border: "1px solid #F9D131",
              padding: "0.7rem",
              borderLeft: 0,
              textAlign: "center",
            },
          }}
        >
          {title}
        </Typography>
      </StyledStack>
      <Typography
        sx={{
          color: "#FFFF",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "0.145rem",
          fontFamily: "League Spartan",
          fontSize: " 1.5rem",
          paddingTop: "2rem",
          textAlign: "left",
          paddingTop: "0rem",
          "@media (max-width: 600px)": {
            fontSize: "1.3rem",
            textAlign: "left",
            border: "1px solid #F9D131",
            borderTop: 0,
            padding: "1rem",
          },
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}

const Image = styled("img")({
  width: "3rem",
  height: "3rem",
  marginRight: "5rem",
  "@media (max-width: 600px)": {
    marginRight: 0,
    width: "1.5rem",
    height: "1.5rem",
    border: "1px solid #F9D131",
    padding: "0.7rem",
  },
});

const StyledStack = styled(Stack)({
  alignItems: "center",
  flexDirection: "column",
  "@media (max-width: 600px)": {
    alignItems: "start",

    flexDirection: "row",
  },
});
