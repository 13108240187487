import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  styled,
  InputBase,
  Slider,
} from "@mui/material";
import { Web3Button } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import RollingDogeROI from "../abis/constant2.json"
import Cards from "./Referals/cards";
function Withdraw({ UserData }) {
  const [value, setValue] = useState(10);
  // const { contract } = useContract(
  //   "0x3CBA93D40F492312083Af9633e8F6d44177939a5"
  // );
  // const address = useAddress();

  // const { data, isLoading } = useContractRead(contract, "Users", [address]);

  const [profit, setProfit] = useState(0);

  useEffect(() => {
    if (UserData !== undefined) {
      // Assuming UserData object contains lastWithdrawTime and investedAmount in Wei
      const timestamp = Math.floor(Date.now() / 1000);
      const lastWithdrawn = ethers.BigNumber.from(UserData.lastWithdrawTime.toString());
      const investedAmountWei = ethers.BigNumber.from(UserData.investedAmount.toString());
          
      const timePassed = timestamp - parseInt(lastWithdrawn.toString(), 10);
      const RATE_PER_SECOND = ethers.BigNumber.from(470000); // Using ethers.js BigNumber
      const DECIMALS = ethers.BigNumber.from(1e13); // Scaling factor using ethers.js BigNumber
          
      // Calculate total profit in Wei using ethers.js BigNumber for safe arithmetic
      const totalProfitWei = investedAmountWei.mul(RATE_PER_SECOND).mul(timePassed).div(DECIMALS);
          
      // Format Wei to Ether
      const totalProfitEther = ethers.utils.formatEther(totalProfitWei.toString());
      const reducedEther = parseFloat(totalProfitEther).toFixed(4); // Reduce decimal places to 4
          
      console.log("Calculated Profit:", reducedEther);
      // Assuming setProfit is a function to update UI or state
      setProfit(reducedEther);
          
    }
  }, [UserData]);

  return (
    <Box
      sx={{
        minHeight: "20rem",
        width: "35%",
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        border: "1px solid #fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        flexWrap: "wrap",
        "@media (max-width: 600px)": {
          minHeight: "5.6875rem",
          width: "100%",
          borderRadius: "1.4875rem",
          padding: "1rem",
          gap: "1rem",
          marginTop: "30px"
        },
      }}
    >
      <Typography
        sx={{
          color: "#159bd7",
          fontSize: "2.5rem",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        }}
      >
        WITHDRAW
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "#000",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
          letterSpacing: "0.145rem",
        }}
      >
        {profit}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          color: "#000",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
          letterSpacing: "0.145rem",
        }}
      >
        $ROLL
      </Typography>

      <Web3Button
        className="web3Button-color"
        contractAddress="0x299e0C8B805eA3A2022b7F8d0F5a3FDCB3f025CD"
        contractAbi={RollingDogeROI}
        action={async (contract) => {
          await contract.call("withdraw", []);
        }}
      >
        Withdraw
      </Web3Button>
    </Box>
  );
}

export default Withdraw;
