import React from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import Logo from "../assets/Roll.png";
import FooterIcons from "../assets/Group 37.svg";
import { BsTwitter } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
function footer() {
  return (
    <Box>
      <StyledAppbar position="static" elevation={0}>
        {/* <Toolbar>
          <Typography variant="h6">RollingDoge</Typography>
          <StyledButton variant="text">Admin Portal</StyledButton>
        </Toolbar> */}

        <StyledContainer>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoImage src={Logo} alt="Logo" />
            <Typography
              variant="h3"
              fontWeight={400}
              sx={{
                lineHeight: "normal",
                fontWeight: "400",
                fontSize: "1.5rem",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              RollingDoge
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              "@media (max-width: 600px)": {
                fontSize: "0.8rem",
              },
            }}
          >
            All Rights Reserved 2024.
          </Typography>

          <Box
            sx={{
              borderRadius: "20px",
              padding: "2px 5px",
              background: "#ffffff1f",
              display: "flex",
              justifyContent: "space-evenly",
              width: "250px"
            }}
          >
            <FaXTwitter
              className="social_icon"
              size={"1.5em"}
              onClick={() =>
                window.open("https://twitter.com/RollingDoger", "_blank")
              }
            />
            <FaTelegramPlane
              className="social_icon"
              size={"1.5em"}
              onClick={() => window.open("https://t.me/rollingdoge", "_blank")}
            />

            <FaTelegramPlane
              className="social_icon"
              size={"1.5em"}
              onClick={() => window.open("https://t.me/rollingdoge1", "_blank")}
            />
            {/* <FaXTwitter
              className="social_icon"
              size={"1.5em"}
              onClick={() =>
                window.open("https://twitter.com/RollingDoge", "_blank")
              }
            />
            <FaTelegramPlane
              className="social_icon"
              size={"1.5em"}
              onClick={() => window.open("https://t.me/RollingDoge", "_blank")}
            /> */}
          </Box>
        </StyledContainer>
      </StyledAppbar>
    </Box>
  );
}

const StyledAppbar = styled(AppBar)(() => ({
  height: "6rem",
  backgroundColor: "#000",
}));

const StyledContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  height: "100%",
  flexWrap: "wrap"
});

const LogoImage = styled("img")({
  width: "2.75rem",
  height: "2.5rem",
  marginRight: 16,
  "@media (max-width: 600px)": {
    width: "2.75rem",
    height: "2.5rem",
  },
});

export default footer;
